.contact-us {
  display: flex;
  justify-content: top;
  align-items: top;
  flex-direction: row;
  padding-top: 80px;
  background-color: rgb(213, 214, 246);
  height: auto;
  min-height: 300px;
}

.contact-us .contact-us-title {
  display: flex;
  flex-direction: column;
  align-items: last baseline;
  width: 35%;
}

.contact-us .contact-us-title h1 {
  font-size: 32px;
  margin: 25px 0;
}

.contact-us .contact-us-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: top;
  width: 65%;
  margin-top: 40px;
}

.contact-us .contact-us-info div {
  padding: 0 50px;
}

.contact-us .contact-us-info a {
  text-decoration: underline;
  color: black;
}

.contact-us .contact-us-info .links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}

.contact-us .contact-us-info .info-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media (max-width: 768px) {
  .contact-us {
    flex-direction: column;
    padding-top: 20px;
  }

  .contact-us .contact-us-title {
    width: 100%;
    align-items: center;
  }

  .contact-us .contact-us-title h1 {
    font-size: calc(32px + 0.8vmin);
  }

  .contact-us .contact-us-info {
    width: 100%;
    margin-top: 20px;
  }

  .contact-us .contact-us-info div {
    padding: 0 20px;
  }

  .contact-us .contact-us-info a {
    font-size: calc(12px + 0.8vmin);
  }
}
