.homepage {
  text-align: center;
}

.homepage-content {
  margin-top: 111px;
  min-height: 1000px;
}

.book-lesson {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.book-lesson-text {
  display: flex;
  flex-direction: column;
  width: 460px;
  height: auto;
  padding: 40px;
}

.book-lesson-text p {
  text-align: left;
  line-height: 1.67em;
  font-size: 24px;
  font-weight: semi-bold;
}

.handy-buttons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.book-lesson-button,
.send-message-button {
  width: 165px;
  height: 45px;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  line-height: 19.6px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition:
    background-color 0.3s ease-in-out,
    transform 0.3s ease-in-out;
}

.book-lesson-button:hover,
.send-message-button:hover {
  background-color: rgba(83, 83, 83, 0.9);
  cursor: pointer;
  transform: scale(1.05);
}

.book-lesson .jets-image {
  position: static;
  width: 460px;
  height: 420px;
  overflow: hidden;
  border: 4px solid rgb(98, 98, 98);
  border-radius: 24px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
}

.book-lesson .jets-image img {
  width: 100%;
  height: 100%;
}

.why-us {
  margin-top: 80px;
}

.why-us-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.why-us-title h2 {
  font-size: 56px;
  margin: 25px 0;
}

.bar {
  width: 80px;
  height: 2px;
  background-color: black;
  margin-bottom: 21px;
}

.link-to-about {
  width: 170px;
  height: 55px;
  background-color: rgb(26, 28, 119);
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 28px;
  font-size: 20px;
  font-weight: semi-bold;
  transition:
    background-color 0.3s ease-in-out,
    color 0.3s ease-in-out,
    transform 0.3s ease-in-out;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.link-to-about:hover {
  background-color: rgb(213, 214, 246);
  color: rgb(26, 28, 119);
  cursor: pointer !important;
  transform: scale(1.05);
}

.benefits-panel {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 20px;
  font-size: 20px;
  text-align: center;
  min-height: 267px;
  overflow: hidden;
  padding: 35px 20px 20px;
}

.benefits-panel svg {
  width: 38px;
  height: 38px;
  object-fit: contain;
}

.container {
  height: auto;
  min-height: 267px;
  perspective: 1000px;
  background-color: transparent;
  padding: 5px;
  transition: 0.3s ease-in-out;
}

.container:hover {
  cursor: pointer;
  transform: scale(1.04);
}

.inner {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(243, 243, 243);
  border: 1px solid rgb(215, 215, 215);
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: 0.3s ease-in-out;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.container.flipped .inner {
  transform: rotateY(180deg);
}

.front,
.back {
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  padding: 0 20px;
}

.front {
  transform: rotateY(0deg);
}

.back {
  transform: rotateY(180deg);
}

@media (max-width: 768px) {
  .homepage-content {
    width: 100%;
  }

  .book-lesson {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .handy-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .handy-buttons a {
    margin: 10px 0;
  }

  .book-lesson-text {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
  }

  .book-lesson-text p {
    font-size: calc(16px + 0.8vmin);
  }

  .jets-image {
    border-radius: 50%;
    max-width: 300px;
    max-height: 300px;
  }

  .why-us {
    margin-top: 20px;
  }

  .why-us-title h2 {
    font-size: 32px;
  }

  .benefits-panel {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
}
