.about-content {
  margin-top: 111px;
  min-height: 1000px;
  margin-bottom: 30px;
}

.about-intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 300px;
}

.about-intro h1 {
  line-height: 1.5em;
  font-size: 24px;
}

.about-intro p {
  line-height: 1.67em;
  font-size: 24px;
  font-weight: semi-bold;
  margin-top: 10px;
}

.about-intro img {
  width: 930px;
  height: 515px;
  object-fit: cover;
  border: 4px solid rgb(98, 98, 98);
  border-radius: 24px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.members {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 50px;
}

.member-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  width: 35%;
  padding: 0 20px 0 30px;
}

.member-title h1 {
  font-size: 48px;
  margin: 10px;
}

.member-title p {
  width: 80%;
  line-height: 1.6em;
  font-size: 20px;
  text-align: left;
}

.member-intros {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-right: 30px;
}

.founding-members,
.tutors {
  display: grid;
  width: 100%;
  padding-bottom: 20px;
}

.founding-members-intro,
.tutors-intro {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  justify-content: flex-start;
}

.profile {
  flex: 1 1 fit-content;
  max-width: 350px;
  /* min-width: max-content; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 3rem;
  padding: 10px 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.member-intros h2 {
  font-size: 24px;
  color: #333;
}

.member-intros h3 {
  font-size: 20px;
  color: #333;
}

.member-intros p {
  line-height: 1.6em;
  font-size: 16px;
  width: 310px;
  font-family: "Open Sans", sans-serif;
  color: #3a3a3a;
  text-align: justify;
}

.member-intros img {
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .about-content {
    margin-top: 111px;
    min-height: 1000px;
    padding: 0 calc(20px + 2vmin);
  }

  .about-intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 20px;
  }

  .about-intro h1 {
    line-height: 1.5em;
    font-size: 24px;
  }

  .about-intro p {
    line-height: 1.67em;
    font-size: 24px;
    font-weight: semi-bold;
    margin-top: 10px;
  }

  .about-intro img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 4px solid rgb(98, 98, 98);
  }

  .members {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }

  .member-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: 0;
    padding: 10px;
  }

  .member-title h1 {
    font-size: 32px;
    margin-left: 10px;
  }

  .member-title p {
    width: 80%;
    line-height: 1.6em;
    font-size: 18px;
  }

  .member-intros {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0 50px 0;
    padding: 0;
    text-align: center;
    overflow: hidden;
  }

  .member-intros div {
    min-width: 0;
  }

  .member-intros h2,
  .member-intros h3 {
    font-size: 20px;
  }

  .member-intros p {
    line-height: 1.6em;
    font-size: 16px;
    width: 100%;
  }

  .member-intros img {
    width: 250px;
    height: 250px;
  }

  .founding-members-intro,
  .tutors-intro {
    justify-content: center;
  }

  .founding-members-intro > div,
  .tutors-intro > div {
    width: 90%;
    padding: 15px;
  }
}
